import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../context/Context.js'
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'

export default function CatPageVideos(){

    const { heroProduct, setHeroProduct, getHeroProduct, product, mediaIds, media, getMediaById, selectedMedia, setSelectedMedia, images, videos } = useContext(Context)
    const [count, setCount] = useState(0)
    useEffect(() => {
        setCount(0)
        media.map(item => item.vidType && setCount(prev => prev += 1))
    },[media])
    
    return(
        <div className="catPageVideos">
            <h1>Videos</h1>
            <div className="catPageVideos-container">
                { media && videos || <h1>Loading ...</h1> }
            </div>
            <Link to='/allVideos' className="viewAll-button">View All ({count})</Link>
        </div>
    )
}