import React, { useState, useContext, useEffect, Suspense, lazy } from 'react'
import TopNav from './components/TopNav.js'
import Home from './Home.js'
import CatPage from './CatPage.js'
import AllArticles from './AllArticles.js'
import AllImages from './AllImages.js'
import AllSocials from './AllSocials.js'
import AllVideos from './AllVideos.js'
//import BottomNav from './components/BottomNav.js' *** This is now being lazy loaded ***
import Download from './components/Download.js'
import Loading from './components/Loading.js'
import { Context } from './context/Context.js'
import { Routes, Route, Navigate } from 'react-router-dom';

const BottomNav = lazy(() => import('./components/BottomNav.js'))

function App() {

  const { showDownload, setShowDownload, isLoading, setIsLoading } = useContext(Context)
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  },[])

  return (
    <div className="app">
      { isLoading && <Loading /> }
        <TopNav />
        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route 
            path='/category'
            element={<CatPage />}
          />
          <Route
            path='/allImages'
            element={<AllImages />}
          />
          <Route
            path='/allArticles'
            element={<AllArticles />}
          />
          <Route
            path='/allVideos'
            element={<AllVideos />}
          />
          <Route
            path='/allSocials'
            element={<AllSocials />}
          />
        </Routes>
        { showDownload && <Download /> }
        <Suspense fallback={ <div>Loading...</div> }>
          <BottomNav />
        </Suspense>
        
    </div>
  );
}

export default App;
