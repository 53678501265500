import React, { useState, useEffect, useContext } from 'react'
import Loading from './components/Loading.js'
import CatPageImages from './components/CatPageImages.js'
import CatPageArticles from './components/CatPageArticles.js'
import CatPageVideos from './components/CatPageVideos.js'
import CatPageSocials from './components/CatPageSocials.js'
import { Context } from './context/Context.js'
import { useNavigate } from 'react-router-dom'

export default function CatPage(){

    const { vector, category, setCategory, categoryHeader, getProductsByCategory, product, setProduct, products, getHeroProduct, heroProduct, setHeroProduct, mediaIds, setMediaIds, media, setMedia, getMediaById, clickLeft, clickRight, productIndex, setProductIndex, nameClick, categoryFromStorage, setCategoryFromStorage, allView, setAllView, isLoading, setIsLoading } = useContext(Context)

    let navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        // setCategory(localStorage.getItem('category'))
        window.scrollTo(0,0)
        getProductsByCategory()
        getHeroProduct(products[0])
        getMediaById()
        setAllView(false)
        setTimeout(() => {
            setIsLoading(false)
        }, 1500)
    },[])
    useEffect(() => {
        // setCategory(localStorage.getItem('category'))
        getProductsByCategory()
        getMediaById()
        getHeroProduct()
    }, [category])

    useEffect(() => {
        getProductsByCategory()
    },[category])

    useEffect(() => {
        getHeroProduct()
    },[products])
    
    useEffect(() => {
        getMediaById()
    },[mediaIds])

    useEffect(() => {
        getHeroProduct()
    },[product])

    useEffect(() => {
        products && setProduct(products[productIndex]?._id || "")
    }, [productIndex])
    
    const productNames = products && products?.map((item, index) => {
        return(
            <h2 className={!product && index === 0 && `catPage-header-names-name-selected` || product === item._id ? `catPage-header-names-name-selected`:`catPage-header-names-name` } onClick={() => nameClick(item._id, index)} key={item._id}>{item.name}</h2> 
        )
    })
    const filter = media.map(media => media.specs)
    const vidFilter =media.map(media => media.vidType)

    return(
        <div className="catPage">
            <header className="catPage-header">
                <div className={`catPage-header-banner-${category || localStorage.getItem('category')}`}>
                    <div className="catPage-header-back" onClick={() => navigate(-1)}>
                        <i class="fa-regular fa-arrow-left-long"></i>                        
                        <h1 className="">Back</h1>
                    </div>
                    <h1 className="catPage-header-title">{(category === 'emf' ? `` : `UltraLux `) || (localStorage.getItem('category') === 'emf' ? `` : `UltraLux `)}<span>{ categoryHeader }</span></h1>
                </div>
                <div className="catPage-header-namesContainer">
                    <h1 id="left" onClick={clickLeft}>{`<`}</h1>
                    <div className="catPage-header-names">
                        {products && productNames} {/* product is the product id of the selected products from productNames */}    
                    </div>
                    <h1 id="right" onClick={clickRight}>{`>`}</h1>
                </div>
            </header>
            { vector }
            <div className="catPage-component-container">
                { (filter.includes('4:1') || filter.includes('3:2') || filter.includes('3:1')) && <CatPageImages /> }
                { (filter.includes('txt') || filter.includes('pdf')) && <CatPageArticles /> }
                { (vidFilter.includes('mp4') || vidFilter.includes('mov')) && <CatPageVideos /> }
                { (filter.includes('1:1') || filter.includes('9:16')) && <CatPageSocials />}
            </div>
        </div>
    )
}