import React, { useState, useContext, useEffect } from 'react'
import { Context } from './context/Context.js'
import { useNavigate } from 'react-router-dom'

export default function AllVideos(){

    const { vector, category, setCategory, categoryHeader, getProductsByCategory, product, setProduct, products, getHeroProduct, heroProduct, setHeroProduct, mediaIds, setMediaIds, media, setMedia, getMediaById, clickLeft, clickRight, productIndex, setProductIndex, nameClick, categoryFromStorage, setCategoryFromStorage, allView, setAllView, isLoading, setIsLoading, images, videos } = useContext(Context)

    let navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        // setCategory(localStorage.getItem('category'))
        window.scrollTo(0,0)
        
        getProductsByCategory()
        getHeroProduct(products[0])
        getMediaById()
        setAllView(false)
        setTimeout(() => {
            setIsLoading(false)
        }, 1500)
        setAllView(true)
    },[])
    useEffect(() => {
        // setCategory(localStorage.getItem('category'))
        getProductsByCategory()
        getMediaById()
        getHeroProduct()
    }, [category])
    useEffect(() => {
        getProductsByCategory()
    },[category])
    useEffect(() => {
        getHeroProduct()
    },[products]) 
    useEffect(() => {
        getMediaById()
    },[mediaIds])
    useEffect(() => {
        getHeroProduct()
    },[product])

    return(
        <div className="allVideos">
            <div className="back-container" onClick={() => navigate(-1)}>
                <i class="fa-regular fa-arrow-left-long"></i>                        
                <h1>Back</h1>
            </div>
            <h1 className="all-title">All Videos <span style={{fontSize: "24px"}}>(click green circle to add to downloads)</span></h1>
            <div className="all-container">
                { media && videos }
            </div>
            { vector }
        </div>
        
    )
}