import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../context/Context.js'

export default function Loading(){

    const { isLoading, setIsLoading } = useContext(Context)

    return(
        <div className="loading">
            <div class="container">
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
            </div>  
        </div>
    )
}