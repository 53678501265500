import React, { useState, useContext, useEffect } from 'react'
import Logo from '../images/Frame 376.png'
import { Link } from 'react-router-dom'
import { Context } from '../context/Context.js'

export default function TopNav(){

    const { triggerLoad, homeLogo } = useContext(Context)
    const [hoverDetails, setHoverDetails] = useState("")
    const message = 
        <h2 className={ `topNav-message ${hoverDetails ? 'show' : '' }` }>
            { hoverDetails === 'home' ? `Navigate Home` 
            : hoverDetails === 'partner' ? `Partner With Us` 
            : hoverDetails === 'contact' ? `Customer Support` 
            : hoverDetails === 'account' && `My Account` }
        </h2>

    return(
        <div className="topNav">
            { homeLogo } { /* This is a link and svg element found in the context api */ }
            <div className={ window.innerWidth > 987 ? "topNav-links" : "topNav-links-mobile"}>
                <Link to='/' onClick={ () => triggerLoad() } onMouseOver={ () =>  setHoverDetails('home') } onMouseOut={ () => setHoverDetails('') } aria-label="Home">
                    <i class="fa-regular fa-house"></i>
                    { window.innerWidth < 988 && hoverDetails === 'home' && message }
                {  window.innerWidth > 987 && `Resource Home` }</Link>
                <Link to='https://ultraluxhealth.com/partner-with-us/' onClick={ () => triggerLoad() } onMouseOver={ () =>  setHoverDetails('partner') } onMouseOut={ () => setHoverDetails("") } aria-label="Partner With Us">
                    <i class="fa-regular fa-handshake"></i>
                    { window.innerWidth < 988 && hoverDetails === 'partner' && message }
                { window.innerWidth > 987 && `Partnership Page` }</Link>
                <a href='mailto:support@ultraluxhealth.com' onClick={ () => triggerLoad(2000) } onMouseOver={ () =>  setHoverDetails('contact') } onMouseOut={ () => setHoverDetails("") } aria-label="Contact Us">
                    <i class="fa-regular fa-envelope"></i>
                    { window.innerWidth < 988 && hoverDetails === 'contact' && message }
                { window.innerWidth > 987 && `Customer Support` }</a>
                { window.innerWidth < 988 && 
                <Link to='https://ultraluxhealth.com/my-account' onClick={ () => triggerLoad(5000) } onMouseOver={ () =>  setHoverDetails('account') } onMouseOut={ () => setHoverDetails("") } aria-label="My Account">
                    <i class="fa-regular fa-user"></i>
                    { window.innerWidth < 988 && hoverDetails === 'account' && message }
                </Link> }
            </div>
            { window.innerWidth > 987 && <Link to='https://ultraluxhealth.com/my-account' onClick={ () => triggerLoad(5000) } className="myAccount-link" aria-label="My Account">
                <i class="fa-regular fa-user"></i>
            </Link> }
        </div>
    )
}