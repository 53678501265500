import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../context/Context.js'

export default function Search(){

    const { handleChangeSearch, search, setSearch, searchResults } = useContext(Context)

    return(
        <div className="search">
            <h1 className="search-title">Welcome to the<br></br><span>UltraLux Resource Library</span></h1>
            <form className="search-form">
                <label className="search-form-label">I'm looking for...</label>
               { searchResults ? 
                <input
                    type='text'
                    onChange={ handleChangeSearch }
                    className="search-form-input"
                    placeholder="Search by Product"
                    style={{borderRadius: "40px 40px 0 0"}}
                /> 
                :
                <input
                    type='text'
                    onChange={ handleChangeSearch }
                    className="search-form-input"
                    placeholder="Search by Product"
                />
                }
            </form>
            { searchResults && 
            <div className="search-results-container">
                { searchResults }
            </div> 
            }
        </div>
    )
}
