import React, { useState, useContext, useEffect } from 'react'
import Search from './components/Search.js'
import Categories from './components/Categories.js'
import { Context } from './context/Context.js'

export default function Home(){

    useEffect(() => {
        localStorage.clear()
    },[])

    return(
        <div className="home">
            <Search />
            <Categories />
        </div>
    )
}