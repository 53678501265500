import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Vector from '../images/Vector 10.png'

export const Context = React.createContext()

// const userAxios = axios.create() ----> This is if we use json web tokens for authorization
// userAxios.interceptors.request.use(config => {
//     const token = localStorage.getItem('token')
//     config.headers.Authorization = `Bearer ${token}`
//     return config
// })

export default function ContextProvider(props){

    const [search, setSearch] = useState([])
    const [product, setProduct] = useState("")
    const [products, setProducts] = useState("")
    const [mediaIds, setMediaIds] = useState([])
    const [media, setMedia] = useState([])
    const [category, setCategory] = useState("")
    const [categoryHeader, setCategoryHeader] = useState("")
    const [heroProduct, setHeroProduct] = useState(products[0] || {}) //This will initially render the first product in the list
    const [productIndex, setProductIndex] = useState(0)
    const [selectedMedia, setSelectedMedia] = useState([])
    const [preview, setPreview] = useState()
    const [showDownload, setShowDownload] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [categoryFromStorage, setCategoryFromStorage] = useState(localStorage.getItem(category) || "")
    const [allView, setAllView] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const vector = 
        <div 
        className="vector-container" 
        onClick={() => setShowDownload(true)}>
            <h2 id="vector-description">Click arrow to download/view selected items</h2>
            <img id="vector" src={Vector} />
            { selectedMedia.length > 0 && selectedMedia.length < 100 ? 
                <div id="number">{selectedMedia.length}</div> 
            : selectedMedia.length > 99 ? 
                <div id="number" style={{fontSize: "12px"}}>99+</div> 
            : 
                <></> 
            }
        </div>

    const triggerLoad = (e) => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, e ? e : 1500)
    }
            

//-----------------------SEARCH COMPONENT-----------------------------
const handleChangeSearch = async (e) => {
    const { value } = e.target
    try {
        const res = await axios.get(`https://ulh-resource-library.onrender.com/product/name/${ value }`)
        //const res2 = await axios.get(`/media/name/${ value }`)
        const data1 = res.data
        //const data2 = res2.data
        //setSearch(data1.concat(data2))
        setSearch(data1)
    } catch(err) {
        console.log(err)
    }
    value.length === 0 && setSearch("")
}

const searchSelection = (item) => {
    setCategory(item.category)
    localStorage.setItem('category', item.category)
    setProduct(item._id)
    setHeroProduct(item)
    setSearch("")
}

const searchResults = search && search.length > 0 && search.map(item => {
    return(
        <Link className="search-results" to='/category' onClick={() => searchSelection(item)}>
            { item.product && <div 
                className={selectedMedia.includes(item._id) ? "search-results-circle-filled" : "search-results-circle"}
                onClick={() => {
                    setSelectedMedia(prev => 
                        selectedMedia.includes(item._id) ? prev.filter(id => id !== item._id) : [...prev, item._id]
                    )
                }}
                >
                </div>}
            <h2 className="search-results-name">{item.name}</h2>
            {/* <h4 className={item.media ? "search-results-product" : "search-results-media"}>{item.media ? `Product` : `Media`}</h4> */}
        </Link>    
    )
})
    
//---------------------------------------------------------------------
//----------------------CATEGORIES COMPONENT---------------------------
async function clickCategory(category){
    localStorage.clear()
    localStorage.setItem('category', category)
    setCategory(localStorage.getItem('category'))
    const res = await axios.get(`https://ulh-resource-library.onrender.com/product/category/${category}`)
    setProduct(res.data[0]._id) 
}
//---------------------------------------------------------------------
//-------------------------CATEGORY PAGE-------------------------------  
const getProductsByCategory = async () => {
    (category || localStorage.getItem('category')) === "air" ? setCategoryHeader("Air Sentry") : (category || localStorage.getItem('category')) === "clean" ? setCategoryHeader("Clean") : (category || localStorage.getItem('category')) === "emf" ? setCategoryHeader("EMF Protection") : (category || localStorage.getItem('category')) === "water" ? setCategoryHeader("Water") : (category || localStorage.getItem('category')) === "sauna" ? setCategoryHeader("Sauna") : setCategoryHeader("Red Light")
    try {
        const res = await axios.get(`https://ulh-resource-library.onrender.com/product/category/${ localStorage.getItem('category') || category }`)
        setProducts(res.data)
    } catch(err) {
        console.log(err)
    }
}
//GET PRODUCT HERO
const getHeroProduct = async () => {
    try {
        const res = await axios.get(`https://ulh-resource-library.onrender.com/product/${ product || products[0]?._id }`)
        setHeroProduct(res.data)
        setMediaIds(res.data.media)
        // setProduct(res.data._id)
    } catch(err) {
        console.log(err)
    }
}
//GET MEDIA FROM PRODUCT HERO
const getMediaById = async () => {
    const mediaArray = await Promise.all(mediaIds?.map(id => {
        const res = axios.get(`https://ulh-resource-library.onrender.com/media/${id}`)
        return res
    }))
    const mappedArray = mediaArray.map(index => index.data)
    setMedia(mappedArray)
}

//arrow functions for catpage header
const clickLeft = () => {
    setIsLoading(true)
    if (productIndex !== 0) {
        setProductIndex(prev => prev - 1);
        const container = document.querySelector('.catPage-header-names');
        if (container) {
            container.scrollLeft -= 200;
        }
    }
    setTimeout(() => {
        setIsLoading(false)
    }, 1000)
}

const clickRight = () => {
    setIsLoading(true)
    if (productIndex !== (products.length - 1)) {
        setProductIndex(prev => prev + 1);
        const container = document.querySelector('.catPage-header-names');
        if (container) {
            container.scrollLeft += 200;
        }
    }
    setTimeout(() => {
        setIsLoading(false)
    }, 1000)
}

function nameClick(id, index){
    setIsLoading(true)
    setProduct(id)
    setProductIndex(index)
    setTimeout(() => {
        setIsLoading(false)
    },1000)
} 
//---------------------------------------------------------------------
//-------------------CatPageImages component---------------------------
const thumbnailClick = (item) => {
    setSelectedMedia(prev => selectedMedia.includes(item._id) ? prev.filter(id => id !== item._id) : [...prev, item._id])
}
const images = media && media.map(item => { //this is also used on the all images page
    if(item.imgType === 'png' && (item.specs === '4:1' || item.specs === '3:2' || item.specs === '3:1')) { //we might need to change this later on to if(item.imgType && item.imgType !== '1:1' && item.imgType !== '9:16') so that we can always add new images of any size and they'll show up under images.
        return(
            <div className={selectedMedia.includes(item._id) ? "catPageImages-thumbnail-selected" : "catPageImages-thumbnail"} key={item._id} onMouseEnter={() => setPreview(item._id)} onMouseLeave={() => setPreview("")} onClick={ () => !allView && thumbnailClick(item) } id="thumbnail">
                <div 
                    id={selectedMedia.includes(item._id) ? "select-circle-clicked" : "select-circle"}
                    // onClick={() => {
                    //     setSelectedMedia(prev => 
                    //         selectedMedia.includes(item._id)
                    //             ? prev.filter(id => id !== item._id)
                    //             : [...prev, item._id]
                    //     );
                    // }}
                    style={{zIndex: "1100"}}
                >
                    <div id="circle-border"></div>
                </div>
                <img 
                    className={preview === item._id ? "thumbnail-preview" : "catPageImages-thumbnail-image"}                 
                    src={item.url || "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"}
                    key={item._id}
                    id="thumbnail-img"
                />
                {preview === item._id && 
                    <div className="preview-container">
                        <a id="openPreview" href={item.url} target="_blank" rel="noreferrer noopener" onClick={(e) => e.stopPropagation()}>Open Preview</a>
                        <h2 id="selectItem" onClick={()=>thumbnailClick(item)} style={{display: "none"}}>{selectedMedia.includes(item._id) ? `Remove Media` : `Add Media`}</h2>
                    </div>
                }
                <h4 className="catPageImages-thumbnail-name" style={item._id === preview ? {opacity: "20%"} : {}}>{item.name}</h4>
            </div>
        )
    }
})
//---------------------------------------------------------------------
//-------------------CatPageArticles component-------------------------
const articles = media && media.map((item, index) => {
    if(item.pdfType === 'pdf' || item.txtType === '.txt' || item.txtType === '.docx' || item.txtType === '.rtf'){
        return(
            <div className={ "catPageArticles-thumbnail"} key={item._id}>
                <div 
                    className={selectedMedia.includes(item._id) ? "catPageArticles-thumbnail-circle-filled" : "catPageArticles-thumbnail-circle"}
                    onClick={() => {
                        setSelectedMedia(prev => 
                            selectedMedia.includes(item._id) ? prev.filter(id => id !== item._id) : [...prev, item._id]
                        )
                    }}
                >
                    { selectedMedia.includes(item._id) && <div id="circle-border"></div>}
                </div>
                {/* <img className="catPageArticles-thumbnail-icon" src={PDF} /> */}
                <i id="pdf" className="fa-regular fa-file-pdf"></i>
                <h2 className="catPageArticles-thumbnail-name">{item.name}</h2>
                <h3 className="catPageArticles-thumbnail-description">{item.description}</h3>
                <a 
                    key={item._id}
                    href={item.url}
                    target="_blank"
                    className="catPageArticles-thumbnail-link"                
                >Open Preview
                </a>
            </div>
        )
    }
})
//---------------------------------------------------------------------
//-------------------CatPageVideos component---------------------------
const videos = media && media.map(item => { 
    if(item.vidType === 'mp4' || item.vidType === 'mov'){
        return(
            <div className="catPageVideos-thumbnail" key={item._id}>
                <div id={selectedMedia.includes(item._id) ? "select-circle-clicked" : "select-circle"}
                    onClick={() => {
                        setSelectedMedia(prev => 
                            selectedMedia.includes(item._id)
                                ? prev.filter(id => id !== item._id)
                                : [...prev, item._id]
                        );
                    }}
                >
                    { selectedMedia.includes(item._id) && <div id="circle-border"></div>}
                </div>
                <iframe
                    className="reactPlayer"
                    onClick={() => console.log('clicked')}
                    controls
                    autoPlay
                    loop
                    type="video/mp4"
                    src={item.url || "https://www.youtube.com/embed/NeuJ5njWPAA"}
                    // onClick={() => {
                    //     setSelectedMedia(prev => 
                    //         selectedMedia.includes(item._id)
                    //             ? prev.filter(id => id !== item._id)
                    //             : [...prev, item._id]
                    //     );
                    // }}   
                    key={item._id}              
                ></iframe>
                <h2 className="catPageVideos-thumbnail-name">{item.name}</h2>
            </div>
            
        )
    }
})
//---------------------------------------------------------------------
//-------------------CatPageSocials component--------------------------
const socials = media && media.map(item => {
    if(item.imgType === 'png' && (item.specs === '1:1' || item.specs === '9:16')){ 
        return(
            <div key={item._id} onMouseEnter={() => setPreview(item._id)} onMouseLeave={() => setPreview("")} className={selectedMedia.includes(item._id) ? `catPageSocials-thumbnail-${item.specs === '1:1' ? `row1` : `row2`}-selected` : `catPageSocials-thumbnail-${item.specs === '1:1' ? `row1` : `row2`}`} onClick={() => !allView && thumbnailClick(item)} id="thumbnail">
                <div id={selectedMedia.includes(item._id) ? "select-circle-clicked" : "select-circle"}
                // onClick={() => {
                //         setSelectedMedia(prev => 
                //             selectedMedia.includes(item._id)
                //                 ? prev.filter(id => id !== item._id)
                //                 : [...prev, item._id]
                //         );
                //     }}
                >
                    <div id="circle-border"></div>
                </div>
                <img 
                    className={preview === item._id ? `catPageSocials-thumbnail-image-${item.specs === '1:1' ? '11-preview' : '916-preview'}` : `catPageSocials-thumbnail-image-${item.specs === '1:1' ? `11` : `916`}`}              
                    src={item.url || "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"}
                    key={item._id}
                    id="thumbnail-img"
                />
                {preview === item._id && 
                <div className="preview-container">
                    <a id="openPreview" href={item.url} target="_blank" rel="noreferrer noopener" onClick={(e) => e.stopPropagation()}>Open Preview</a>
                    <h2 id="selectItem" onClick={()=>thumbnailClick(item)} style={{display: "none"}}>{selectedMedia.includes(item._id) ? `Remove Media` : `Add Media`}</h2>
                </div>
                }
                <h4 className="catPageSocials-thumbnail-name" style={item._id === preview ? {opacity: "20%"} : {}}>{item.name}</h4>
            </div>
        )
    }
})
//---------------------------------------------------------------------
//------------------All Articles Page----------------------------------
const allArticles = media && media.map(item => {
    if(item.pdfType === 'pdf' || item.txtType === '.txt' || item.txtType === '.docx' || item.txtType === '.rtf'){
        return(
            <div className="allArticles-thumbnail" key={item._id} onMouseEnter={() => setPreview(item._id)} onMouseLeave={() => setPreview("")}>
                <div id={selectedMedia.includes(item._id) ? "select-circle-clicked" : "select-circle"}
                    onClick={() => {
                            setSelectedMedia(prev => 
                                selectedMedia.includes(item._id)
                                    ? prev.filter(id => id !== item._id)
                                    : [...prev, item._id]
                            );
                        }}
                    >
                        <div id="circle-border"></div>
                </div>
                <iframe className={preview === item._id ? "allArticles-thumbnail-iframe-preview" : "allArticles-thumbnail-iframe"} src={`${item.url}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}></iframe>
                {preview === item._id && 
                <div className="preview-container">
                    <a id="openPreview" href={item.url} target="_blank" rel="noreferrer noopener">Open Preview</a>
                    <h2 id="selectItem" onClick={()=>thumbnailClick(item)} style={{display: "none"}}>{selectedMedia.includes(item._id) ? `Remove Media` : `Add Media`}</h2>
                </div>
                }
                <h2 className="allArticles-thumbnail-name">{item.name}</h2>
                <h3 className="allArticles-thumbnail-description">{item.description}</h3>
            </div>
        )
    }
})
//---------------------------------------------------------------------
//--------------------SVG IMAGE FOR TOPNAV.JS-----------------------------
const homeLogo = 
<Link to='https://ultraluxhealth.com' onClick={ () => triggerLoad(2000) }>   
    <svg viewBox="0 0 189 52" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="ultraluxhealth.com" className="topNav-logo" alt="ULH Logo"> 
        <g clip-path="url(#clip0_253_111)"> 
            <path d="M42.7255 52H35.1322C25.8168 51.9873 16.8868 48.297 10.3007 41.7386C3.71463 35.1803 0.0102363 26.2892 0 17.0156V0H7.56426C16.8838 0.00254603 25.8213 3.68728 32.4139 10.2449C39.0065 16.8026 42.7152 25.697 42.7255 34.9748V38.0659H35.7029V34.9748C35.6952 27.5478 32.7282 20.4271 27.4528 15.1754C22.1774 9.92365 15.0247 6.96987 7.56426 6.96222H6.99355V17.0156C7.00123 24.4435 9.96798 31.5652 15.2431 36.8184C20.5181 42.0717 27.6708 45.0276 35.1322 45.0378H42.7255V52Z" fill="#376543">
            </path> 
            <path d="M66.2308 17.5356V29.7748C66.2308 33.8963 63.0871 36.6407 58.386 36.6407C53.685 36.6407 50.5413 33.8963 50.5413 29.7748V17.5356H54.5459V29.4282C54.6613 30.3611 55.1155 31.2199 55.8228 31.8426C56.5301 32.4654 57.4418 32.8091 58.386 32.8091C59.3303 32.8091 60.242 32.4654 60.9493 31.8426C61.6566 31.2199 62.1108 30.3611 62.2262 29.4282V17.5356H66.2308Z" fill="#152519">
            </path> 
            <path d="M71.9475 17.5356H75.9521V33.097H81.4657V36.3807H71.9475V17.5356Z" fill="#152519">
            </path> 
            <path d="M83.468 17.5356H95.0756V20.8193H91.2064V36.3807H87.2502V20.8193H83.381L83.468 17.5356Z" fill="#152519">
            </path> 
            <path d="M103.462 32.7119V36.3807H99.4574V17.5356H106.925C111.22 17.5356 113.696 20.5015 113.696 23.6889C113.706 29.5726 103.462 32.7119 103.462 32.7119ZM110.359 29.0526L114.383 36.3807H109.962L106.374 29.6978M109.585 23.6889C109.585 21.763 108.463 20.8 106.161 20.8H103.462V28.4459C103.462 28.4459 109.585 26.963 109.585 23.6889Z" fill="#152519">
            </path> 
            <path d="M139.726 17.5356H143.721V33.097H149.234V36.3807H139.726V17.5356Z" fill="#152519">
            </path> 
            <path d="M168.223 17.5356V29.7748C168.223 33.8963 165.079 36.6407 160.378 36.6407C155.677 36.6407 152.533 33.8963 152.533 29.7748V17.5356H156.528V29.4282C156.634 30.3694 157.085 31.2386 157.794 31.8698C158.503 32.5011 159.422 32.85 160.373 32.85C161.324 32.85 162.242 32.5011 162.952 31.8698C163.661 31.2386 164.112 30.3694 164.218 29.4282V17.5356H168.223Z" fill="#152519">
            </path> 
            <path d="M180.372 23.9585H180.526L184.396 17.5356H188.913L183.109 26.963L189.01 36.3807H184.415L180.546 29.9482H180.391L176.522 36.3807H171.937L177.866 26.963L172.063 17.5356H176.599L180.372 23.9585Z" fill="#152519">
            </path> 
            <path d="M126.706 17.3333C122.015 17.3333 118.871 20.0874 118.871 24.2089V36.6504H122.866V33.097H130.546V36.6504H134.551V24.1896C134.551 20.0874 131.436 17.3333 126.706 17.3333ZM122.837 29.8519V24.5459C122.952 23.613 123.406 22.7542 124.114 22.1315C124.821 21.5087 125.733 21.165 126.677 21.165C127.621 21.165 128.533 21.5087 129.24 22.1315C129.948 22.7542 130.402 23.613 130.517 24.5459V29.8037L122.837 29.8519Z" fill="#152519">
            </path> 
            <path d="M64.2768 50.9793V46.6363H68.5523V45.6156H64.2768V43.5548H68.8425V42.5341H63.1161V52H68.9199V50.9793H64.2768Z" fill="#152519">
            </path> 
            <path d="M84.7932 50.9793V42.5341H83.6421V52H88.0723V50.9793H84.7932Z" fill="#152519">
            </path> 
            <path d="M96.5942 42.5437V43.5548H94.1663V52H93.0056V43.5548H90.5776V42.5437H96.5942Z" fill="#152519">
            </path> 
            <path d="M106.228 42.5341V48.5045H101.16V42.5341H99.999V52H101.16V49.5156H106.228V52H107.37V42.5341H106.228Z" fill="#152519">
            </path> 
            <path d="M57.7379 42.5341V48.5045H52.6693V42.5341H51.5085V52H52.6693V49.5156H57.7379V52H58.8793V42.5341H57.7379Z" fill="#152519">
            </path> 
            <path d="M76.0972 42.4089C75.1668 42.4114 74.2751 42.7805 73.6172 43.4355C72.9592 44.0905 72.5885 44.9781 72.5859 45.9044V51.9904H73.7564V49.5155H78.8153V51.9904H79.9567V45.9044C79.9567 44.979 79.5881 44.0914 78.9317 43.4361C78.2753 42.7808 77.3847 42.4114 76.4551 42.4089H76.0972ZM78.8153 48.5044H73.7564V45.9429C73.7615 45.278 74.0311 44.6423 74.5061 44.1748C74.9811 43.7073 75.6228 43.4463 76.2907 43.4489C76.9603 43.4489 77.6024 43.7137 78.0759 44.185C78.5493 44.6564 78.8153 45.2956 78.8153 45.9622V48.5044Z" fill="#152519">
            </path> 
        </g> 
        <defs> 
            <clipPath id="clip0_253_111"> 
            <rect width="189" height="52" fill="white">
            </rect> 
            </clipPath> 
        </defs> 
    </svg>
</Link>

    
    return(
        <Context.Provider
            value={{
                homeLogo,
                vector,
                search, setSearch,
                searchResults,
                clickCategory,
                product, setProduct,
                products, setProducts,
                heroProduct, setHeroProduct,
                productIndex, setProductIndex,
                clickLeft, clickRight,
                mediaIds, setMediaIds,
                media, setMedia,
                category, setCategory,
                categoryHeader, setCategoryHeader,
                handleChangeSearch,
                getProductsByCategory,
                getHeroProduct,
                getMediaById,
                selectedMedia, setSelectedMedia,
                images,
                articles,
                videos,
                socials,
                allArticles,
                showDownload, setShowDownload,
                showEdit, setShowEdit,
                nameClick,
                categoryFromStorage, setCategoryFromStorage,
                allView, setAllView,
                isLoading, setIsLoading,
                triggerLoad   
            }}
        >
            {props.children}
        </Context.Provider>
    )
}