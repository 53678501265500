import { useState, useEffect, useContext } from 'react'
import { Context } from '../context/Context.js'
import axios from 'axios'

export default function Download(){

    const { selectedMedia, setSelectedMedia, showDownload, setShowDownload, showEdit, setShowEdit } = useContext(Context)
    const [mediaElements, setMediaElements] = useState()


    async function optionNo() {
        setShowEdit(true); 
        
    }
    
    function optionYes(){
        mediaElements.map(async item => {
            try{
                const encodedUrl = encodeURIComponent(item.url)
                const res = await axios.get(`https://ulh-resource-library.onrender.com/media/${item.imgType ? `img` : item.pdfType ? `pdf` : `vid`}/${encodedUrl}`, { //when txt types are availabe we will need to update this axios request and add another mediaRouter for (`/media/txt/url`)
                    responseType: 'arraybuffer' // Specify arraybuffer responseType for binary data
                })
                const blob = new Blob([res.data], { type: item.imgType ? res.headers['content-type'] : item.pdfType ? 'application/pdf' : 'video/mp4' })
                const newUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = newUrl;
                link.download = item.name;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(newUrl);
            } catch(error) {
                console.error("Error fetching the file:", error);
            }
        })
        setShowDownload(false)
        setSelectedMedia([])
        setMediaElements()
    } 

    function x(){
        setShowDownload(false)
        setShowEdit(false)
    }
    function clearAll(){
        setSelectedMedia([])
        setMediaElements([])
        setShowDownload(false)
        setShowEdit(false)
    }
    function remove(item){
        setSelectedMedia(prev => prev.filter(media => media !== item._id))
        setMediaElements(prev => prev.filter(element => element._id !== item._id))
        selectedMedia.length === 1 && x()
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const elements = await Promise.all(selectedMedia.map(async (media) => {
                    try {
                        const res = await axios.get(`https://ulh-resource-library.onrender.com/media/${media}`)
                        return res.data;
                    } catch(err) {
                        console.error(err)
                        return null;
                    }
                }))
                setMediaElements(elements)
            } catch (error) {
                console.error(error)
            }
        }
        fetchData();
    }, [selectedMedia]);

    return(
        <div className="download">
            <div className="download-box">
                { !showEdit && 
                <div className="download-alert">
                    <h1 className="download-x" onClick={() => x()}>X</h1>
                    <h2>You are about to download {selectedMedia.length} media element{selectedMedia.length !== 1 ? `s` : ``}</h2>
                    <div className="download-alert-options">
                        <h2 onClick={() => optionYes()}>Download Files</h2>
                        <h2 onClick={() => optionNo()}>Edit Selections</h2> 
                    </div>
                </div>}
                { showEdit && 
                <div className="download-edit">
                    <h1 className="download-x" onClick={() => x()}>X</h1>
                    <h2>You currently have the following items ready for download...</h2>
                    <h3 onClick={() => clearAll()}>Clear All</h3>
                    <h3 onClick={() => optionYes()}>Download</h3>
                    <ul>
                        { mediaElements && mediaElements.map(item => {
                            return(
                                <div className="download-mappedMedia">
                                    <li>{item.name}</li>
                                    <h3 onClick={() => remove(item)}>Remove</h3>
                                </div>
                            )
                        }) }
                    </ul>
                </div>}
            </div>
        </div>
    )
}